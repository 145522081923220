import Switch from '@/components/UI/FormElements/Switch';
import AsyncSelect from 'react-select/async';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './DurationPicker.module.scss';
import { _isEmptyString, _notNil } from '@/littledash';
import {
  allDayToOption,
  DurationPickerState,
  fromOptionData,
  initialState,
  minutesInDay,
  stateToDurationValue,
  toggleAllDayTask,
  toOptionData,
} from '@/components/UI/DurationPicker/DurationPicker.util';
import { TaskTimeDuration } from '@/model/Task.model';

type DurationPickerProps = { value: TaskTimeDuration; disabled?: boolean; onChange: (value: TaskTimeDuration) => void };
export const DurationPicker: FC<DurationPickerProps> = ({ value, disabled = false, onChange }) => {
  const [state, updateState] = useState<DurationPickerState>(() => initialState(value));
  const { fromOptionsMap, fromOptions, fromOptionsFuse } = useMemo(fromOptionData, []);
  const { toOptionsMap, toOptions, toOptionsFuse } = useMemo(() => toOptionData(state.from), [state.from]);
  useEffect(() => {
    const state = initialState(value);
    updateState((prevState) => {
      if (prevState.allDay === state.allDay && prevState.from === state.from && prevState.duration === state.duration) {
        return prevState;
      }
      return state;
    });
  }, [updateState, value]);
  const handleStateChange = (update: (prev: DurationPickerState) => DurationPickerState) => {
    if (!disabled) {
      updateState((prev) => {
        const result = update(prev);
        onChange(stateToDurationValue(result));
        return result;
      });
    }
  };
  const loadFromOptions = useCallback(
    (inputValue: string) => {
      return Promise.resolve(fromOptionsFuse.search(inputValue).map((result) => result.item));
    },
    [fromOptions, fromOptionsFuse]
  );
  const loadToOptions = useCallback(
    (inputValue: string) => {
      return Promise.resolve(
        _isEmptyString(inputValue) ? toOptions : toOptionsFuse.search(inputValue).map((result) => result.item)
      );
    },
    [toOptions, toOptionsFuse]
  );
  return (
    <div
      className="flex flex-row items-center justify-start"
      data-test-component="DurationPicker"
      data-test-element="container"
    >
      <span data-test-element="from-select-container" className="ui__select">
        <AsyncSelect
          classNamePrefix="ui__select"
          className={styles.DurationSelect}
          defaultOptions={fromOptions}
          loadOptions={loadFromOptions}
          isDisabled={state.allDay || disabled}
          value={state.allDay ? fromOptionsMap.get(0) : fromOptionsMap.get(state.from)}
          isOptionSelected={(option) => option.offset === state.from}
          isOptionDisabled={(option) => option.offset + state.duration > minutesInDay}
          onChange={(value) => {
            if (_notNil(value)) {
              handleStateChange((prev) => ({ ...prev, from: value.offset }));
            }
          }}
        />
      </span>

      <div className="mh2">to</div>
      <span className="mr2 ui__select" data-test-element="to-select-container">
        <AsyncSelect
          classNamePrefix="ui__select"
          className={styles.DurationSelect}
          defaultOptions={toOptions}
          loadOptions={loadToOptions}
          isDisabled={state.allDay || disabled}
          value={state.allDay ? allDayToOption : toOptionsMap.get(state.duration)}
          isOptionSelected={(option) => option.duration === state.duration}
          onChange={(value) => {
            if (_notNil(value)) {
              handleStateChange((prev) => ({ ...prev, duration: value.duration }));
            }
          }}
        />
      </span>
      <Switch
        value={state.allDay}
        disabled={disabled}
        className="mr2"
        onChange={() => handleStateChange(toggleAllDayTask)}
      />
      <span>All day</span>
    </div>
  );
};
