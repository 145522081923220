import { ReleaseInfo } from '@/generated/ReleaseInfo';
import { getSessionCorrelationId } from '@/littledash';
import InVivoError from '@/model/InVivoError.ts';
import * as Sentry from '@sentry/react';
import { UserApiId } from '@/model/User.model';
import { TeamApiId } from '@/model/Team.model';

export class SentryUtils {
  static init() {
    if (AppConfig.sentryEnabled) {
      Sentry.init({
        dsn: AppConfig.sentryDSN,
        integrations: [Sentry.browserTracingIntegration()],
        environment: AppConfig.environment,
        release: ReleaseInfo.releaseVersion,
        tracesSampleRate: 1.0,
        attachStacktrace: true,
        allowUrls: [AppConfig.allowedHostPattern],
        tracePropagationTargets: [AppConfig.allowedHostPattern],
      });
      Sentry.setContext('Session Correlation', { id: getSessionCorrelationId() });
    }
  }

  static setUser(userApiId: UserApiId, email: string, teamApiId: TeamApiId, teamName: string): void {
    if (AppConfig.sentryEnabled) {
      Sentry.setUser({ id: userApiId, email });
      Sentry.setContext('Tenant', { id: teamApiId, name: teamName });
      Sentry.setTags({ tenant: teamName, team_api_id: teamApiId });
      Sentry.setContext('Session Correlation', { id: getSessionCorrelationId() });
    }
  }

  /**
   *
   * @param exception {unknown}
   * @param context {Record<string,any>|undefined}
   */
  static captureException(exception: unknown, context: Record<string, unknown>) {
    if (AppConfig.sentryEnabled) {
      Sentry.captureException(exception, (scope): Sentry.Scope => {
        if (exception instanceof InVivoError) {
          const context = { ...exception.context };
          scope.setContext('InVivo Error Context', context);
          scope.setLevel(exception.level);
          scope.setFingerprint([
            '{{ default }}',
            exception.name,
            exception.level,
            context.ApiMethod,
            context.ApiStatusCode,
          ]);
        }
        scope.setContext('Extra Info', {
          'Current URL': window.location.href,
          Online: navigator?.onLine ?? false,
          Focused: document.hasFocus(),
          'Visibility State': document.visibilityState,
          'Round Trip Time': `${navigator?.connection?.rtt ?? '-1'}ms`,
          ...(context ?? {}),
        });
        scope.setContext('Session Correlation', { id: getSessionCorrelationId() });
        return scope;
      });
    } else {
      console.error('Sentry.captureException()', exception); // eslint-disable-line no-console
    }
  }
}
