import NoData from '@/components/NoData';
import {
  initialState,
  OnSearchChange,
  ScheduleContextProvider,
  scheduleContextReducer,
} from '@/components/Studies/Tasks/Schedule.context.ts';
import { ScheduleHeader } from '@/components/Studies/Tasks/ScheduleHeader.tsx';
import { Task } from '@/components/Studies/Tasks/Task';
import { TaskTypeData } from '@/components/Studies/Tasks/TaskTypeDetail.tsx';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import { _isEmpty, _isNil, _isNotBlank, _notNil } from '@/littledash';
import { ISODate, ISODateTime } from '@/model/Common.model.ts';
import { Observation } from '@/model/Observation.model.ts';
import { SampleType } from '@/model/Sample.model.ts';
import type { Study } from '@/model/Study.model';
import { TaskApiId, TasksExecutionAfterSave, TaskV1 } from '@/model/Task.model';
import { StudyTreatment } from '@/model/Treatment.model.ts';
import * as Auth from '@/support/auth';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useApiPagination } from '@/support/Hooks/api/useApiPagination';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook.ts';
import { RouteService } from '@/support/RouteService.ts';
import { DateRenderFormat, DateUtils } from '@/utils/Date.utils';
import { useModalAction } from '@/utils/modal.tsx';
import { tz } from '@date-fns/tz';
import { useVirtualizer } from '@tanstack/react-virtual';
import cn from 'classnames';
import { differenceInCalendarDays, isToday, parseISO, startOfDay } from 'date-fns';
import { FC, memo, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import style from './Schedule.module.scss';

interface ScheduleProps {
  study: Study;
}

interface DateRow {
  type: 'date';
  start: ISODateTime;
  studyDay: number;
}

interface TaskRow {
  type: 'task';
  start: ISODateTime;
  apiId: TaskApiId;
}

const DateItem = memo(({ data }: { data: DateRow }) => (
  <div className="flex flex-row justify-start near-black f4 pv4 items-center">
    {isToday(data.start, { in: tz(DateUtils.timezone()) }) ? <span className="fw6 mr2">Today</span> : null}
    <DateTimeRenderer value={data.start} format={DateRenderFormat.Date} />
    <div className="ba b--moon-gray br-pill f8 ph2 pv1 ml2">Study day {data.studyDay}</div>
  </div>
));

const throttle = (signal: AbortSignal, window: number = 100): Promise<boolean> =>
  new Promise<boolean>((resolve) => {
    const id = setTimeout(() => resolve(!signal.aborted), window);
    signal.addEventListener('abort', () => {
      clearTimeout(id);
      resolve(false);
    });
  });

const studyDay = (studyStart: ISODate, studyTimezone: string, taskStart: ISODateTime) => {
  const inTimezone = tz(studyTimezone);
  const difference = differenceInCalendarDays(
    startOfDay(taskStart, { in: inTimezone }),
    parseISO(`${studyStart}T00:00:00.000`, { in: inTimezone }),
    { in: inTimezone }
  );
  return difference >= 0 ? difference + 1 : difference;
};

export const Schedule: FC<ScheduleProps> = ({ study }) => {
  const [state, dispatch] = useReducer(scheduleContextReducer, initialState());
  const { response: scheduleDataResponse, invoke: fetchTaskScheduleData } = useApiHook({
    endpoint: 'GET /api/v1/studies/{studyId}/task-schedule-data',
    path: { studyId: study.api_id },
    invokeOnInit: false,
  });
  const {
    response: tasksDataResponse,
    loading: tasksDataLoading,
    invoke: fetchTasks,
  } = useApiHook({
    endpoint: 'GET /api/v1/tasks',
    invokeOnInit: false,
    query: {
      study_api_id: [study.api_id],
      sort: 'start',
      order: 'asc',
      perPage: 50,
      start: state.search.start ?? undefined,
      include: ['target_animal_count'],
    },
  });
  const { response: samplesGlossaryResponse } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'team-glossary.list',
    query: { group: 'samples' },
    options: { onError: { toast: false, slug: 'samples-glossary-load' } },
  });
  const { response: observationsGlossaryResponse } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'team-glossary.list',
    query: { group: 'observations' },
    options: { onError: { toast: false, slug: 'observations-glossary-load' } },
  });
  const { response: treatmentsResponse } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'studies.treatments',
    path: { studyId: study.id },
    options: { onError: { toast: false, slug: 'treatments-load' } },
  });

  // Stores any tasks updated to render instead of the API response
  const [updatedTasks, setUpdatedTasks] = useState<Record<TaskV1['id'], TaskV1>>({});
  const isWriteUser = Auth.isWriteUserForStudy(study);
  const history = useHistory();
  const { openModal, closeModal } = useModalAction();
  const scrollElement = useRef<HTMLDivElement>(null);

  const taskTypeData = useMemo<TaskTypeData>(() => {
    const measurements = study.settings.calculations.reduce(
      (acc, calculation) => ({ ...acc, [calculation.id]: calculation.name }),
      {}
    );
    const samples = ((samplesGlossaryResponse?.body as { data: Array<SampleType> })?.data ?? []).reduce(
      (acc, sample) => ({ ...acc, [sample.id]: sample.title }),
      {}
    );
    const observations = ((observationsGlossaryResponse?.body as { data: Array<Observation> })?.data ?? []).reduce(
      (acc, observation) => ({ ...acc, [observation.id]: observation.title }),
      {}
    );
    const treatments = ((treatmentsResponse?.body as { data: Array<StudyTreatment> })?.data ?? []).reduce(
      (acc, treatment) => ({ ...acc, [treatment.id]: treatment.display_name }),
      {}
    );
    return {
      treatments,
      measurements,
      samples,
      observations,
    };
  }, [study, observationsGlossaryResponse, samplesGlossaryResponse, treatmentsResponse]);

  const { pages, hasNextPage, nextPage, reset } = useApiPagination({ response: tasksDataResponse });
  const { rows, taskMap } = useMemo(() => {
    const userTimezone = tz(DateUtils.timezone());
    const taskList = (pages?.flat() ?? []) as Array<TaskV1>;
    const currentTaskDays = taskList.reduce((acc, task) => {
      const startDate = DateUtils.formatISO(startOfDay(task.duration.start, { in: userTimezone }).getTime());
      const taskDayData = acc.get(startDate);
      if (_isNil(taskDayData)) {
        return acc.set(startDate, {
          studyDay: studyDay(study.started_on, study.config.timezone, task.duration.start),
          tasks: [task],
        });
      }
      return acc.set(startDate, { ...taskDayData, tasks: [...taskDayData.tasks, task] });
    }, new Map<ISODateTime, { studyDay: number; tasks: Array<TaskV1> }>());

    const taskMap = new Map(taskList.map((task) => [task.id, task]));
    const rows: Array<DateRow | TaskRow> = Array.from(currentTaskDays.entries()).flatMap(
      ([date, { studyDay, tasks }]) => [
        { type: 'date', start: date, studyDay },
        ...tasks.map<TaskRow>((task) => ({ type: 'task', start: task.duration.start, apiId: task.id })),
      ]
    );
    return { rows, taskMap };
  }, [pages, study.started_on, study.config.timezone]);

  const virtualizerRowCount = useMemo(
    () =>
      (scheduleDataResponse?.body?.task_days ?? []).reduce(
        (acc, taskDay) => acc + taskDay.count,
        scheduleDataResponse?.body?.task_days?.length ?? 0
      ),
    [scheduleDataResponse]
  );

  const taskVirtualizer = useVirtualizer({
    count: virtualizerRowCount,
    getScrollElement: () => scrollElement?.current,
    estimateSize: () => 100,
    paddingEnd: 30,
  });
  useEffect(() => {
    fetchTaskScheduleData({
      path: { studyId: study.api_id },
      query: {
        ...(_isNotBlank(state?.search?.start) ? { start: state?.search?.start } : {}),
        ...(_isNotBlank(state?.search?.text) ? { text: state?.search?.text } : {}),
      },
    });
  }, [study.api_id, state?.search?.start, state?.search?.text, updatedTasks]);

  const loadMoreTasks = useCallback(
    () =>
      fetchTasks({
        query: {
          study_api_id: [study.api_id],
          sort: 'start',
          order: 'asc',
          text: state.search.text ?? undefined,
          start: state.search.start ?? undefined,
          include: ['target_animal_count'],
          page: nextPage,
          perPage: 50,
        },
      }),
    [fetchTasks, study, state, nextPage]
  );

  const virtualRows = taskVirtualizer.getVirtualItems();

  useEffect(() => {
    const abortController = new AbortController();
    const lastItemIndex = virtualRows.at(-1)?.index;
    if (
      _notNil(lastItemIndex) &&
      rows.length > 0 &&
      lastItemIndex >= rows.length - 1 &&
      !tasksDataLoading &&
      hasNextPage
    ) {
      throttle(abortController.signal).then((load) => {
        if (load) {
          loadMoreTasks();
        }
      });
    }
    return () => abortController.abort();
  }, [loadMoreTasks, hasNextPage, tasksDataLoading, virtualRows, rows]);

  const handleTaskUpdate = (task: TaskV1): void => {
    setUpdatedTasks({ ...updatedTasks, [task.id]: task });
  };
  const openWorkflow = (taskApiIds: Array<TaskApiId>, after_save?: TasksExecutionAfterSave) => {
    history.push(
      RouteService.web({
        route: 'studies.workflow',
        path: { id: study.id },
        query: { type: 'task-execution', taskApiId: taskApiIds, after_save: after_save as string },
      }).route
    );
  };
  const handleTaskExecution = () => {
    openModal('EXECUTE_TASKS', {
      tasks: [...state.selectedTasks].reduce<Array<TaskV1>>(
        (acc, taskApiId) => (taskMap.has(taskApiId) ? [...acc, taskMap.get(taskApiId) as TaskV1] : acc),
        []
      ),
      onExecute: (response: { taskApiIds: Array<TaskApiId>; after_save?: TasksExecutionAfterSave }) => {
        closeModal();
        openWorkflow(response.taskApiIds, response.after_save);
      },
      onCancel: closeModal,
    });
  };
  const onSearchChange = useCallback<OnSearchChange>(
    async (data) => {
      if (state.search.text !== data.text || state.search.start !== data.start) {
        dispatch({ type: 'update-search', data: { ...data } });
        reset();
        taskVirtualizer.scrollToIndex(0, { behavior: 'auto', align: 'start' });
        await fetchTasks({
          query: {
            study_api_id: [study.api_id],
            sort: 'start',
            order: 'asc',
            text: data.text ?? undefined,
            start: data.start ?? undefined,
            include: ['target_animal_count'],
            perPage: 50,
          },
        });
      }
    },
    [taskVirtualizer, fetchTasks, reset, dispatch]
  );

  return (
    <ScheduleContextProvider value={{ state, dispatch, userTimezone: DateUtils.timezone(), onSearchChange }}>
      <ScheduleHeader
        scheduleData={scheduleDataResponse?.body}
        executeTasks={handleTaskExecution}
        isWriteUserForStudy={isWriteUser}
      />
      {!tasksDataLoading && _isEmpty(rows) && _notNil(tasksDataResponse?.body) ? (
        <NoData
          title="No tasks found"
          text="Tasks can be added in the Study settings section."
          link={`/studies/${study.id}/settings/`}
          btnTxt="Study settings"
          isFullScreen
        />
      ) : (
        <div
          className="ph4 mb4"
          data-test-component="Schedule"
          data-test-element="scroll-container"
          ref={scrollElement}
          style={{
            height: '90%',
            overflowY: 'auto',
            contain: 'strict',
          }}
        >
          <div
            className="w-100 relative"
            style={{
              height: `${taskVirtualizer.getTotalSize()}px`,
            }}
          >
            <div
              style={{
                transform: `translateY(${virtualRows?.[0]?.start ?? 0}px)`,
              }}
              className="w-100 absolute top-0 left-0"
            >
              {virtualRows.map((virtualRow) => {
                const item = rows?.[virtualRow.index];
                switch (item?.type) {
                  case 'date': {
                    return (
                      <div
                        key={`date-${virtualRow.key}`}
                        data-index={virtualRow.index}
                        ref={taskVirtualizer.measureElement}
                        data-test-element="item-container"
                      >
                        <DateItem data={item} />
                      </div>
                    );
                  }
                  case 'task': {
                    const task = updatedTasks?.[item.apiId] ?? taskMap.get(item?.apiId);
                    if (_notNil(task)) {
                      return (
                        <div
                          key={`task-${virtualRow.key}`}
                          data-index={virtualRow.index}
                          ref={taskVirtualizer.measureElement}
                          data-test-element="item-container"
                        >
                          <div className="pb3">
                            <Task
                              task={task}
                              study={study}
                              typeData={taskTypeData}
                              onTaskUpdate={handleTaskUpdate}
                              onTaskExecute={(task) => openWorkflow([task.id])}
                              isWriteUser={isWriteUser}
                            />
                          </div>
                        </div>
                      );
                    }
                  }
                }
                return (
                  <div
                    key={`task-skeleton-${virtualRow.key}`}
                    data-index={virtualRow.index}
                    ref={taskVirtualizer.measureElement}
                    data-test-element="item-container"
                  >
                    <div className={cn('pb3', style.itemSkeletonContainer)}>
                      <div className={style.taskSkeleton}>
                        <span className={style.loading}></span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </ScheduleContextProvider>
  );
};
