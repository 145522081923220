import { ReleaseInfo } from '@/generated/ReleaseInfo';
import { getSessionCorrelationId } from '@/littledash';
import InVivoError from '@/model/InVivoError.ts';
import { datadogRum } from '@datadog/browser-rum';
import { UserApiId } from '@/model/User.model';
import { TeamApiId } from '@/model/Team.model';

export class DatadogUtils {
  static init() {
    if (AppConfig.datadogEnabled) {
      datadogRum.init({
        applicationId: AppConfig.datadogRumApplicationId as string,
        clientToken: AppConfig.datadogRumClientToken as string,
        site: 'datadoghq.com',
        useSecureSessionCookie: true,
        service: 'in-vivo.web',
        env: `${AppConfig.environment}`,
        version: ReleaseInfo.releaseVersion,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [AppConfig.allowedHostPattern],
      });
      datadogRum.setGlobalContextProperty('session_correlation', { id: getSessionCorrelationId() });
      datadogRum.setGlobalContextProperty('Commit', {
        commitId: ReleaseInfo.commitId,
        commitTime: ReleaseInfo.commitTime,
      });
      datadogRum.startSessionReplayRecording();
    }
  }

  static setUser(userApiId: UserApiId, email: string, teamApiId: TeamApiId, teamName: string) {
    if (AppConfig.datadogEnabled) {
      datadogRum.setUser({ id: `${userApiId}`, email });
      datadogRum.setGlobalContextProperty('Tenant', { id: teamApiId, name: teamName });
      datadogRum.setGlobalContextProperty('session_correlation', { id: getSessionCorrelationId() });
    }
  }

  /**
   *
   * @param exception {unknown}
   * @param context {Record<string,any>|undefined}
   */
  static captureException(exception: unknown, context?: Record<string, any>) {
    if (AppConfig.datadogEnabled) {
      const appErrorContext = Object.entries(exception instanceof InVivoError ? exception.context : {}).reduce(
        (acc, [key, value]) => ({ ...acc, [`InVivoError: ${key}`]: value }),
        {}
      );
      datadogRum.addError(exception, {
        'Current URL': window.location.href,
        Online: navigator?.onLine ?? false,
        Focused: document.hasFocus(),
        'Visibility State': document.visibilityState,
        'Round Trip Time': `${navigator?.connection?.rtt ?? '-1'}ms`,
        ...appErrorContext,
        ...(context ?? {}),
      });
    } else {
      console.error('Datadog.captureException()', exception); // eslint-disable-line no-console
    }
  }
}
