// @ts-nocheck: converted from JS

import Icon from '@/components/UI/Icon/index';
import { _isNotEmpty, _notNil } from '@/littledash';
import { FC, useLayoutEffect, useRef, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { TreatmentType } from '@/model/Treatment.model';

const defaultAdditional = {
  page: 1,
};

interface LookupProps {
  name?: string;
  isMulti?: unknown;
  loadOptions?: unknown;
  loadTokenOptions?: (data: { inputValue: string; token: string }) => void;
  defaultPagination?: boolean;
  tokenPagination?: boolean;
  handleSelect?: unknown;
  defaultValue?: unknown;
  value?: unknown;
  placeholder?: unknown;
  disabled?: unknown;
  classNamePrefix?: unknown;
  closeMenuOnSelect?: unknown;
  isFixed?: unknown;
  onBlur?: unknown;
  additional?: unknown;
  noOptionsMessage?: unknown;
  isLoading?: unknown;
  reduceOptions?: (prevOptions: Array<unknown>, nextOptions: Array<unknown>) => Array<unknown>;
  cacheOptions?: boolean;
}

const Lookup: FC<LookupProps> = ({
  name,
  isMulti = false,
  loadOptions,
  loadTokenOptions,
  defaultPagination = true,
  tokenPagination = false,
  handleSelect,
  defaultValue,
  value,
  placeholder = 'Search...',
  disabled = false,
  classNamePrefix,
  closeMenuOnSelect = true,
  isFixed = false,
  onBlur,
  additional,
  noOptionsMessage,
  isLoading,
  reduceOptions,
  cacheOptions = true,
}) => {
  const selectRef = useRef();
  const [controlLocation, setControlLocation] = useState(selectRef.current?.getBoundingClientRect());
  const [token, setToken] = useState<string>(null);
  const [searchBy, setSearchBy] = useState<string>(null);
  const [tokenPaginationComplete, setTokenPaginationComplete] = useState<boolean>(false);

  useLayoutEffect(() => {
    setControlLocation(selectRef.current?.getBoundingClientRect());
  }, []);

  const selectScrollBarStyle = {
    menuList: (base) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '9px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f6f8fa',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    }),
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      height: '45px',
      border: 0,
    }),
    menuPortal: () => ({
      zIndex: '1000',
      width: controlLocation?.width,
      position: 'fixed',
      backgroundColor: 'red',
      left: controlLocation?.left,
    }),
    ...selectScrollBarStyle,
  };

  const usePortal = isFixed
    ? {
        closeMenuOnScroll: true,
        menuPortalTarget: selectRef.current,
        menuInPortal: true,
        styles: selectStyles,
        menuPosition: 'fixed',
      }
    : {
        styles: selectScrollBarStyle,
      };

  const loadMoreOptions = async (inputValue, _, { page }) => {
    if (tokenPagination && _isNotEmpty(inputValue) && (!tokenPaginationComplete || inputValue !== searchBy)) {
      setSearchBy(inputValue);

      const { options, nextToken } = await loadTokenOptions({
        inputValue,
        token: searchBy === inputValue ? token : null,
      });

      setToken(nextToken);

      const hasMore = _isNotEmpty(nextToken) || defaultPagination;

      if (_isNotEmpty(nextToken)) {
        setTokenPaginationComplete(false);
      } else {
        setTokenPaginationComplete(true);
      }

      return {
        options,
        hasMore,
      };
    }

    if (defaultPagination) {
      const { options, hasMore, additional } = await loadOptions({
        inputValue,
        page,
      });

      const defaultAdditional = {
        page: page + 1,
      };

      return {
        options,
        hasMore,
        additional: { ...defaultAdditional, ...additional },
      };
    }
  };

  const customGetOptionLabel = ({ label, value }) => {
    return _notNil(value?.registry_id) ? (
      RegistryIDDisplayLabel({ label, value })
    ) : (
      <div className="flex items-center">
        {_notNil(value?.entity_id) && (
          <Icon icon="jeffy_p_circle" width="24" height="24" className="blue dib pt1" viewBox="0 0 20 20" />
        )}
        <span className="ml1 w-100 truncate">{label}</span>
      </div>
    );
  };

  const RegistryIDDisplayLabel: FC<{ label: string; value: TreatmentType }> = ({ label, value }) => {
    return (
      <div className="flex items-center">
        <Icon icon="jeffy_p_circle" width="24" height="24" className="blue dib pt1" viewBox="0 0 20 20" />
        <span className="ml1 w-100 truncate">
          {value.registry_id} &nbsp; &#x2022; {label}
        </span>
      </div>
    );
  };

  return (
    <span ref={selectRef} data-testid={`${name}-lookup`}>
      <AsyncPaginate
        {...usePortal}
        getOptionLabel={customGetOptionLabel}
        additional={{ ...defaultAdditional, ...additional }}
        debounceTimeout={600}
        closeMenuOnSelect={closeMenuOnSelect}
        defaultValue={defaultValue}
        onChange={handleSelect}
        loadOptions={loadMoreOptions}
        placeholder={placeholder}
        isMulti={isMulti}
        cacheOptions={cacheOptions}
        disabled={disabled}
        defaultOptions
        value={value}
        className="ui__select f6"
        classNamePrefix={classNamePrefix || 'ui__select'}
        onBlur={onBlur}
        noOptionsMessage={noOptionsMessage}
        isLoading={isLoading}
        reduceOptions={reduceOptions}
        cacheUniqs={[name]}
      />
    </span>
  );
};

export default Lookup;
