import { TaskDuration } from '@/model/Task.model.ts';
import { DateRenderFormat, DateTimeRenderFormat, DateUtils } from '@/utils/Date.utils.ts';
import { differenceInMinutes } from 'date-fns';
import { FC, useMemo } from 'react';
import { _isEmptyString } from '@/littledash';

export const TaskTime: FC<{ taskDuration: TaskDuration; timezone: string }> = ({ taskDuration, timezone }) => {
  const { timeString, timezoneString } = useMemo<{ timeString: string; timezoneString?: string }>(() => {
    const displayTimezone =
      DateUtils.timezoneOffsetMilliseconds(taskDuration.start, timezone) !==
      DateUtils.timezoneOffsetMilliseconds(taskDuration.start, DateUtils.timezone());
    const startDate = DateUtils.roundDate(new Date(taskDuration.start), 'minutes');
    const endDate = DateUtils.roundDate(new Date(taskDuration.end), 'minutes');
    const startDateString = DateUtils.renderDateTime(startDate.toISOString(), { format: DateRenderFormat.Date });
    const startTimeString = DateUtils.renderDateTime(startDate.toISOString(), { format: DateTimeRenderFormat.Time });
    const durationInMinutes = differenceInMinutes(endDate, startDate);
    const durationInHours = durationInMinutes / 60;
    const allDayTask = durationInHours >= 24;
    const durationString = `${durationInHours % 1 === 0 ? Math.floor(durationInHours) : durationInHours.toFixed(1)}hr${durationInHours > 1 ? 's' : ''}`;
    const timezoneData = {
      id: timezone,
      name: DateUtils.timezoneName(taskDuration.start, timezone, 'long') ?? '',
      abbreviation: DateUtils.timezoneAbbreviation(taskDuration.start, timezone),
    };
    const timezoneString = displayTimezone
      ? `${_isEmptyString(timezoneData.name) ? timezoneData.id : timezoneData.name} • ${timezoneData.abbreviation}`
      : undefined;

    if (allDayTask) {
      if (displayTimezone || durationInHours > 24) {
        return { timeString: `${startDateString} from ${startTimeString} for ${durationString}`, timezoneString };
      } else {
        return { timeString: `${startDateString}`, timezoneString };
      }
    } else {
      if (displayTimezone) {
        return {
          timeString: `${startDateString} from ${startTimeString} for ${durationString}`,
          timezoneString,
        };
      } else {
        return {
          timeString: `${startDateString} from ${startTimeString} to ${DateUtils.renderDateTime(endDate.toISOString(), { format: DateTimeRenderFormat.Time })}`,
          timezoneString,
        };
      }
    }
  }, [taskDuration, timezone]);

  return (
    <span data-tooltip-id="global-tooltip-id" data-tooltip-content={timezoneString}>
      {timeString}
    </span>
  );
};
