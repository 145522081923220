import Loading from '@/components/Loading';
import { errorToast, infoToast } from '@/helpers.tsx';
import { _isNil, _notNil } from '@/littledash';
import { ISODate } from '@/model/Common.model.ts';
import InVivoError from '@/model/InVivoError.ts';
import { Study, StudyApiId } from '@/model/Study.model';
import { TaskApiId, TasksExecutionAfterSave } from '@/model/Task.model.ts';
import { WorkflowTemplateApiId, WorkflowTemplateV1 } from '@/model/WorkflowTemplate.model.ts';
import { ApiService } from '@/support/ApiService.ts';
import { useFetchEntity } from '@/support/Hooks/fetch';
import useMountedState from '@/support/Hooks/fetch/useMountedState.ts';
import { useQueryParams } from '@/support/Hooks/useQueryParams/useQueryParams.ts';
import { featuresSelector } from '@/support/Selectors';
import './Workflow.scss';
import { createSelector } from '@reduxjs/toolkit';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Workflow from './Show';
import { workflowTemplateDefaults } from './WorkflowTemplate.utils';
import { WorkflowProvider } from '@/utils/workflow/WorkflowProvider.tsx';

const workflowSetupStateSelector = createSelector([featuresSelector], (features) => ({
  typedTasks: features?.typed_tasks ?? false,
}));

export const WorkflowV2 = () => {
  const queryParameters = useQueryParams();
  const { id: studyId } = useParams<{ id: string }>();
  const { typedTasks } = useSelector(workflowSetupStateSelector);

  const { entity: study, entityLoading: studyLoading } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
  });

  if (studyLoading && _isNil(study)) {
    return null;
  }

  if (typedTasks && _notNil(study?.api_id)) {
    if (queryParameters.has('workflowTemplateApiId')) {
      return (
        <WorkflowTemplateSetup
          studyApiId={study.api_id}
          template={{
            type: 'workflow',
            workflowTemplateApiId: queryParameters.get('workflowTemplateApiId') as WorkflowTemplateApiId,
          }}
        />
      );
    } else if (queryParameters.get('type') === 'task-execution') {
      return (
        <WorkflowTemplateSetup
          studyApiId={study.api_id}
          template={{
            type: 'task-execution',
            tasks: queryParameters.getAll('taskApiId') as Array<TaskApiId>,
            after_save: queryParameters.get('after_save') as TasksExecutionAfterSave,
          }}
        />
      );
    }
  }
  return (
    <WorkflowProvider>
      <Workflow />;
    </WorkflowProvider>
  );
};
export type Template =
  | {
      type: 'workflow';
      workflowTemplateApiId: WorkflowTemplateApiId;
    }
  | {
      type: 'task-execution';
      tasks: Array<TaskApiId>;
      after_save?: TasksExecutionAfterSave;
    };

export interface WorkflowTemplateSetupProps {
  studyApiId: StudyApiId;
  template: Template;
}

const loadWorkflowTemplate = async (
  studyApiId: StudyApiId,
  template: WorkflowTemplateSetupProps['template']
): Promise<{ template: WorkflowTemplateV1; measured_at: ISODate | undefined }> => {
  const templateType = template.type;
  switch (templateType) {
    case 'workflow': {
      const response = await ApiService.call({
        endpoint: 'GET /api/v1/studies/{studyApiId}/workflow-template/{workflowTemplateApiId}',
        path: { studyApiId, workflowTemplateApiId: template.workflowTemplateApiId },
        options: { onError: { toast: false, slug: 'workflow-template-load' } },
      });
      if (response.type === 'success') {
        return { template: response.body, measured_at: undefined };
      }
      throw new InVivoError('Could not load workflow template', {
        slug: 'workflow-template-load',
        cause: response.error,
      });
    }
    case 'task-execution': {
      const response = await ApiService.call({
        endpoint: 'POST /api/v1/studies/{studyApiId}/tasks/execute',
        path: { studyApiId },
        body: { tasks: template.tasks, after_save: template.after_save },
        options: { onError: { toast: false, slug: 'task-execution-workflow-template-generate' } },
      });
      if (response.type === 'success') {
        infoToast('Workflow filtered to selected task animals');
        return { template: response.body.workflow.template, measured_at: response.body.workflow.date };
      }
      throw new InVivoError('Could not load workflow template', {
        slug: 'task-execution-workflow-template-load',
        cause: response.error,
      });
    }
  }
  return Promise.reject(new InVivoError(`Unknown type [${templateType}]`, { slug: 'unknown-template-type' }));
};
type TemplateLoadState =
  | { status: 'loading' }
  | { status: 'loaded'; template: WorkflowTemplateV1; measured_at: ISODate | undefined }
  | { status: 'error' };
export const WorkflowTemplateSetup: FC<WorkflowTemplateSetupProps> = ({ studyApiId, template }) => {
  const isMounted = useMountedState();
  const [templateLoadState, updateTemplateLoadState] = useState<TemplateLoadState>({ status: 'loading' });

  useEffect(() => {
    const abortController = new AbortController();
    if (_notNil(studyApiId) && _notNil(template)) {
      loadWorkflowTemplate(studyApiId, template)
        .then(({ template, measured_at }) => {
          if (isMounted()) {
            updateTemplateLoadState({ status: 'loaded', template, measured_at });
          }
        })
        .catch(() => {
          if (isMounted()) {
            errorToast('Problem fetching Workflow configuration, please try again.');
            updateTemplateLoadState({ status: 'error' });
          }
        });
    }
    return () => abortController.abort('effect teardown');
  }, [studyApiId, template, updateTemplateLoadState, isMounted]);

  switch (templateLoadState.status) {
    case 'loading':
      return <Loading />;
    case 'loaded':
      return (
        <WorkflowProvider>
          <Workflow
            templateDefaults={workflowTemplateDefaults(templateLoadState.template, templateLoadState.measured_at)}
          />
        </WorkflowProvider>
      );
    default:
      return (
        <WorkflowProvider>
          <Workflow />;
        </WorkflowProvider>
      );
  }
};
