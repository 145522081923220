import { _notNil, uuid } from '@/littledash';
import type { ID } from '@/model/Common.model';
import { TreatmentGroup } from '@/model/TreatmentGroup.model.ts';
import type { ClassificationType, Treatment } from '@/model/Treatment.model.ts';

export const tempEntityArrayToMap = <T extends { temp_id?: string | undefined }>(entities: Array<T>): Map<ID, T> =>
  new Map(
    (entities ?? []).map((entity) => {
      const tempId = entity?.temp_id ?? uuid();
      return [tempId, { ...entity, temp_id: tempId }];
    })
  );
export type InvalidMetadataFields = { type: 'invalid-metadata'; fields: Array<number> };

export const validateGroups = (
  groups: Array<TreatmentGroup>,
  metadataFields: Array<number>
): Map<string, InvalidMetadataFields> =>
  groups.reduce((acc, group) => {
    const invalidMetadataIssue = validateGroupMetadata(group, metadataFields);
    if (_notNil(invalidMetadataIssue)) {
      acc.set(group?.temp_id ?? '', invalidMetadataIssue);
    }
    return acc;
  }, new Map<string, InvalidMetadataFields>());

const validateGroupMetadata = (
  group: TreatmentGroup,
  metadataFields: Array<number>
): InvalidMetadataFields | undefined => {
  const invalidMetadataFields = (metadataFields ?? []).reduce<Array<number>>(
    (acc, metadataField: number) =>
      (group?.metadata?.map(({ glossary_id }) => glossary_id) ?? []).includes(metadataField)
        ? acc
        : [...acc, metadataField],
    []
  );
  if (invalidMetadataFields.length > 0) {
    return { type: 'invalid-metadata', fields: invalidMetadataFields };
  }
};

export const filterTreatmentsByClassification = (
  treatments: Array<Treatment>,
  classificationType: ClassificationType
) => {
  return treatments.filter((treatment) =>
    classificationType === 'disease_induction'
      ? treatment.treatment_classification === 'disease_induction'
      : treatment.treatment_classification !== 'disease_induction'
  );
};
