import { useScheduleContext } from '@/components/Studies/Tasks/Schedule.context.ts';
import Button from '@/components/UI/Button';
import DatePickerNative from '@/components/UI/DatePickerNative';
import Icon from '@/components/UI/Icon';
import { _isEmptyString, _isNil, _noop, _notNil } from '@/littledash.ts';
import { ISODate } from '@/model/Common.model.ts';
import { TaskScheduleDataV1 } from '@/model/Task.model.ts';
import { DateRenderFormat, DateUtils } from '@/utils/Date.utils.ts';
import { tz } from '@date-fns/tz';
import { format, isAfter, isBefore } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form@latest';
import { useDebouncedCallback } from 'use-debounce';

interface ScheduleSearchBarProps {
  scheduleData: TaskScheduleDataV1;
}

type FormData = {
  text: string | null;
  start: ISODate;
};

const clampDate = (date: ISODate, min: ISODate, max: ISODate, clampTo?: ISODate) => {
  if (isAfter(date, max)) {
    return clampTo ?? max;
  } else if (isBefore(date, min)) {
    return clampTo ?? min;
  }
  return date;
};
export const ScheduleSearchBar: FC<ScheduleSearchBarProps> = ({ scheduleData }) => {
  const { state, onSearchChange, userTimezone } = useScheduleContext();
  const searchInitialised = useRef(false);
  const debouncedOnSearchChange = useDebouncedCallback(onSearchChange, 400, { maxWait: 5000, trailing: true });

  const { minDate, maxDate } = useMemo(() => {
    if (_isEmptyString(state.search.text ?? '')) {
      return {
        minDate: DateUtils.renderDateTime(scheduleData?.duration?.start, {
          defaultResponse: '',
          format: DateRenderFormat.ISODate,
          timezone: userTimezone,
        }),
        maxDate: DateUtils.renderDateTime(scheduleData?.duration?.end, {
          defaultResponse: '',
          format: DateRenderFormat.ISODate,
          timezone: userTimezone,
        }),
      };
    }
    return {
      minDate: DateUtils.renderDateTime(scheduleData?.filtered_duration?.start, {
        defaultResponse: '',
        format: DateRenderFormat.ISODate,
        timezone: userTimezone,
      }),
      maxDate: DateUtils.renderDateTime(scheduleData?.filtered_duration?.end, {
        defaultResponse: '',
        format: DateRenderFormat.ISODate,
        timezone: userTimezone,
      }),
    };
  }, [state.search.text, userTimezone, scheduleData]);

  const defaultValues = useCallback((): FormData => {
    return {
      text: state.search.text ?? null,
      start: clampDate(
        format(_notNil(state?.search?.start) ? new Date(state.search.start) : new Date(), DateRenderFormat.ISODate, {
          in: tz(userTimezone),
        }),
        minDate,
        maxDate,
        minDate
      ),
    };
  }, [state.search, userTimezone, minDate, maxDate]);
  const formMethods = useForm<FormData>({
    defaultValues: async () => defaultValues(),
  });
  useEffect(() => {
    formMethods.reset(defaultValues());
  }, [formMethods, defaultValues]);

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((data) =>
      debouncedOnSearchChange({
        text: data.text,
        start: DateUtils.startOfDay(data.start ?? DateUtils.dateNow(), { timezone: userTimezone }),
      })
    )();
  }, [formMethods, userTimezone, debouncedOnSearchChange]);

  useEffect(() => {
    if (!searchInitialised.current && typeof onSearchChange === 'function' && _isNil(state?.search?.start)) {
      searchInitialised.current = true;
      const data = defaultValues();
      onSearchChange({
        text: data.text,
        start: DateUtils.startOfDay(data.start ?? DateUtils.dateNow(), { timezone: userTimezone }),
      }).catch(_noop);
    }
  }, [state?.search?.start, defaultValues, onSearchChange, searchInitialised]);
  const searchText = useWatch({ control: formMethods.control, name: 'text' });
  return (
    <div
      className="flex flex-row justify-start"
      style={{ width: '650px' }}
      data-test-component="ScheduleSearchBar"
      data-test-element="container"
    >
      <div className="relative w-100 pr2">
        <span className="absolute z-5" style={{ top: '13px', left: '10px' }}>
          <Icon icon="search" width="24" height="24" />
        </span>
        <Controller
          control={formMethods.control}
          name="text"
          render={({ field }) => (
            <input
              type="text"
              data-test-element="search-text-input"
              className="ui__keyword-search-bar"
              style={{ marginBottom: 0, maxWidth: 'none' }}
              placeholder="Search by task title"
              autoComplete="off"
              value={field.value ?? ''}
              onChange={(event) => {
                field.onChange(_isEmptyString(event.target.value ?? '') ? null : event.target.value.trim());
              }}
              onBlur={field.onBlur}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSubmit();
                }
              }}
            />
          )}
        />

        {(searchText ?? '').trim().length > 0 ? (
          <Button
            onClick={() => {
              formMethods.setValue('text', null, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
              handleSubmit();
            }}
            stateless
            style={{ padding: '0', top: '0.8em', right: '1.4em' }}
            className="blue f6 absolute right-0"
            testId="clear-search-text"
          >
            Reset
          </Button>
        ) : null}
      </div>
      <Controller
        control={formMethods.control}
        name="start"
        render={({ field }) => (
          <DatePickerNative
            style={{ marginBottom: 0 }}
            value={field.value ?? DateUtils.dateNow()}
            min={minDate}
            testId="seatch-date"
            max={maxDate}
            onChange={(value) => {
              if (_isEmptyString(value ?? '')) {
                field.onChange(defaultValues().start);
              } else {
                field.onChange(clampDate(value, minDate, maxDate));
              }
              handleSubmit();
            }}
            onBlur={field.onBlur}
          />
        )}
      />
    </div>
  );
};
