import { samplesConfigObj } from '@/components/Glossary/Sections/SampleTypes/SamplesConfig';
import { DateTimeEditor } from '@/components/UI/SpreadSheet/editors/DateTimeEditor';
import type { CustomColumnSettings, CustomGridSettings } from '@/components/UI/SpreadSheet/SpreadSheet.model';
import { _isEmptyString, _isNil, _isNotDeepEqual, _isNotEmpty, _isNumber, _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { MetadataField } from '@/model/Metadata.model';
import type { SampleEdit, SampleMeasurementKey, SampleType } from '@/model/Sample.model';
import { ApiService } from '@/support/ApiService';
import { createMetaMap, defaultMetadataToColumnSettingMapper } from '@/support/hot';
import type { CellValue, RowObject } from 'handsontable/common';
import type { CellProperties, ColumnSettings } from 'handsontable/settings';
import type { CustomSample } from './BulkSampleEdit';

export type ColumnWrapper = Pick<CustomGridSettings, 'columns' | 'colHeaders'>;

export const getMetaColumns = (
  selectedRows: Array<CustomSample> | undefined = [],
  metadata: Array<MetadataField>
): Array<MetadataField> => {
  const glossaryIDs = selectedRows?.reduce<Array<ID>>((ids, row) => {
    if (row.metadata) {
      const metas = row.metadata.filter((m) => !ids.includes(m.glossary_id)).map((m) => m.glossary_id);
      ids = [...ids, ...metas];
    }
    return ids;
  }, []);
  if (_isNotEmpty(metadata) && _isNotEmpty(glossaryIDs)) {
    return metadata.filter((data) => glossaryIDs.includes(data.id));
  }
  return [];
};

export const baseColumnData = (sampleType?: SampleType): ColumnWrapper => {
  const columns: Array<CustomColumnSettings> = [
    {
      data: 'api_id',
      type: 'text',
      readOnly: true,
      value: 'Sample API ID',
      width: 150,
    },
    {
      data: 'sample_id',
      type: 'text',
      value: 'Sample ID',
      allowInvalid: true,
      // Ideally this could be done in a batch, but once again HoT strikes down any sense
      // Realistically there is a max of 50 edited at a single time so this solution
      // will suffice for now until HoT gets ripped out
      validator: function (value: string, callback: (valid: boolean) => void) {
        return isSampleIdUniqueInTeam(this as CellProperties, value, callback);
      },
      validationErrorMessage: (value: unknown) => 'Sample ID is required and must be unique in team',
      width: 150,
    },
    {
      data: 'subject.name',
      readOnly: true,
      type: 'text',
      value: 'Animal name',
      width: 150,
    },
    {
      data: 'type',
      readOnly: true,
      type: 'text',
      value: 'Sample type',
      width: 150,
    },
    {
      data: 'date',
      validator: (value: string, callback: (valid: boolean) => void) => callback(!isNaN(Date.parse(value))),
      type: DateTimeEditor.type,
      value: 'Date taken',
      width: 200,
    },
    {
      data: 'comments',
      type: 'text',
      value: 'Comments',
      width: 200,
      validator: function (value: string, callback: (valid: boolean) => void) {
        callback((value ?? '').length <= 255);
      },
      validationErrorMessage: () => 'Comments have a max length of 255 characters',
    },
    ...(sampleType?.options.details.flatMap<CustomColumnSettings>((type) => [
      {
        data: detailValueSetter(type),
        value: `${type.slice(0, 1).toUpperCase()}${type.slice(1)} Value`,
        type: 'numeric',
        validator: function (value: string, callback: (valid: boolean) => void) {
          callback(detailValueValidator(this as CellProperties, value));
        },
        width: 150,
        validationErrorMessage: () => 'Value is required when a unit is present, and be numeric',
      },
      {
        data: detailUnitSetter(type),
        value: `${type.slice(0, 1).toUpperCase()}${type.slice(1)} Unit`,
        type: 'dropdown',
        source: samplesConfigObj[type].units,
        width: 150,
        validator: function (value: string, callback: (valid: boolean) => void) {
          callback(detailUnitValidator(this as CellProperties, value, samplesConfigObj[type].units));
        },
        validationErrorMessage: () => 'Unit is required when a value is present',
      },
    ]) ?? []),
  ];

  return { columns, colHeaders: columns.map((c) => c.value) };
};

const isSampleIdUniqueInTeam = async (cell: CellProperties, sampleId: string, callback: (valid: boolean) => void) => {
  if (_isNil(sampleId)) {
    callback(false);
    return;
  }

  // This is generally hateful but we can thank HoT for having a ludicrous way of storing data references
  // HoT stores the data as a 3D array, if something has broken here check the column order matches the
  // following numbers
  const sampleApiIdColumn = 0;
  const sampleIdColumn = 1;

  const cellData = cell.instance.getData();
  cellData.splice(cell.row, 1);

  if (cellData.some((sample: Record<string, string>) => sample[sampleIdColumn] === sampleId)) {
    callback(false);
  } else {
    const result = await ApiService.call({
      endpoint: 'GET /api/v1/samples/{sampleApiId}/unique/{sampleId}',
      path: { sampleApiId: cell.instance.getData()[cell.row][sampleApiIdColumn], sampleId },
    });
    callback(result.type === 'success' ? (result.body.success ?? false) : false);
  }
};

// Currently the value cell for a detail is the cell before the unit, so this is
// used as the validation reference
export const detailValueValidator = (cell: CellProperties, value: string): boolean =>
  (_notNil(value) && !_isEmptyString(value) && _isNumber(Number(value))) ||
  (_isNil(value) && _isNil(cell.instance.getData()[cell.row][cell.col + 1]));

const detailValueSetter = (type: SampleMeasurementKey) => (row: RowObject, value: CellValue) => {
  const currentDetails = (row as CustomSample)?.details?.[type];
  if (_notNil(currentDetails) && typeof value != 'undefined') {
    row.details[type].value = value;
  } else if (_isNil(currentDetails) && typeof value != 'undefined') {
    row.details[type] = {
      value,
      key: type,
    };
  }
  return currentDetails?.value;
};

const detailUnitValidator = (cell: CellProperties, unit: string, units: Array<string>): boolean =>
  (_notNil(unit) && units.includes(unit)) || (_isNil(unit) && _isNil(cell.instance.getData()[cell.row][cell.col - 1]));

const detailUnitSetter = (type: SampleMeasurementKey) => (row: RowObject, value: CellValue) => {
  const currentDetails = (row as CustomSample)?.details?.[type];
  if (_notNil(currentDetails) && typeof value != 'undefined') {
    row.details[type].unit = value;
  } else if (_isNil(currentDetails) && typeof value != 'undefined') {
    row.details[type] = {
      unit: value,
      key: type,
    };
  }
  return currentDetails?.unit;
};

export const baseSettings: CustomGridSettings = {
  ...baseColumnData(),
  // Hides the API ID column, would be good to remove this once fully moved from DB IDs
  hiddenColumns: { columns: [0] },
  columnSorting: false,
  className: 'htMiddle',
  manualColumnResize: true,
  contextMenu: false,
  rowHeights: 50,
  height: 500,
  rowHeaders: true,
};

export const generateColumnData = (displayedSampleMetadata: Array<MetadataField>, sampleType?: SampleType) =>
  displayedSampleMetadata.reduce<ColumnWrapper>(({ columns, colHeaders }, metadata) => {
    if (metadata.field_type) {
      const { columnHeader, columnSettings } = defaultMetadataToColumnSettingMapper(metadata);

      return {
        colHeaders: [...(colHeaders as Array<string>), columnHeader],
        columns: [...(columns as Array<CustomColumnSettings>), columnSettings],
        // Hides the API ID column, would be good to remove this once fully moved from DB IDs
        hiddenColumns: { columns: [0] },
      };
    }
    return {
      colHeaders,
      columns,
      // Hides the API ID column, would be good to remove this once fully moved from DB IDs
      hiddenColumns: { columns: [0] },
    };
  }, baseColumnData(sampleType));

export const generateSampleBulkUpdateList = (
  initialSamples: Array<CustomSample> = [],
  editedSamples: Array<CustomSample> = [],
  columns: Array<ColumnSettings>
) => {
  const initialSamplesMap = new Map(initialSamples.map((group) => [group.id, group]));

  const updates = editedSamples.reduce<SampleEdit>((acc, sample) => {
    const { id, api_id, details, date, sample_id, comments, meta } = sample;
    // Compares the initial samples against the new, only sending changed rows
    // Would be good to build on this to only send the exact properties changed, but
    // this is a limitation of the current deep equals
    if (_isNotDeepEqual(initialSamplesMap.get(id), sample)) {
      acc.push({
        api_id: api_id,
        details: Object.values(details),
        date: date,
        sample_id: sample_id,
        comments: comments,
        meta: createMetaMap(meta, columns),
      });
    }
    return acc;
  }, []);

  return updates;
};
