import { uuid } from '@/littledash';
import InVivoError from '@/model/InVivoError.ts';
import { DatadogUtils } from '@/utils/Datadog.utils';
import { SentryUtils } from '@/utils/SentryUtils';
import { AxiosError, isAxiosError, isCancel } from 'axios';

export class ExceptionHandler {
  static captureException(exception: InVivoError, context?: Record<string, unknown>): void {
    const exceptionCorrelationId = uuid();
    DatadogUtils.captureException(exception, {
      ...(context ?? {}),
      ExceptionCorrelationId: exceptionCorrelationId,
    });
    if (exception?.shouldEmit === false) {
      return;
    }

    if (isAxiosError(exception)) {
      const axiosErr = exception as AxiosError;
      const axiosCode = axiosErr?.response?.status;
      if (
        axiosErr.code === AxiosError.ERR_NETWORK ||
        axiosErr.code === AxiosError.ECONNABORTED ||
        isCancel(axiosErr) ||
        axiosCode === 401
      ) {
        return;
      }
    }
    SentryUtils.captureException(exception, {
      ...(context ?? {}),
      ExceptionCorrelationId: exceptionCorrelationId,
    });
  }
}
