import { _notNil, uuid } from '@/littledash.ts';
import { AxiosError, isAxiosError, isCancel } from 'axios';

export default class InVivoError extends Error {
  #context: Record<string, string>;
  #level: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';
  #emit = true;

  constructor(
    message: string,
    options: ErrorOptions & {
      context?: Record<string, string>;
      slug?: string;
      level?: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';
      emit?: 'always';
    }
  ) {
    super(message, options);
    this.name = _notNil(options?.slug) ? `InVivoError:${options?.slug}` : 'InVivoError';
    this.#level = options.level ?? 'error';
    if (isAxiosError(options.cause)) {
      const axiosCode = options.cause?.response?.status;
      this.#context = Object.freeze({
        id: uuid(),
        ApiMethod: (options.cause.config?.method ?? '-').toUpperCase(),
        ApiURL: options.cause?.config?.baseURL ?? '-',
        ApiStatus: options.cause.response?.statusText ?? '-',
        ApiStatusCode: `${axiosCode ?? '-'}`,
        ApiResponseBody: JSON.stringify(options.cause?.response?.data),
        ...(options.context ?? {}),
      });
      this.#emit = !(
        options.cause.code === AxiosError.ERR_NETWORK ||
        options.cause.code === AxiosError.ECONNABORTED ||
        isCancel(options.cause) ||
        this.#context.ApiStatusCode === '401'
      );
    } else if (options?.cause instanceof InVivoError) {
      this.#emit = options.cause.shouldEmit;
      this.#context = Object.freeze({
        id: uuid(),
        ...(options?.cause?.context ?? {}),
        ...(options.context ?? {}),
      });
    } else {
      this.#context = Object.freeze({
        id: uuid(),
        ...(options.context ?? {}),
      });
    }
    if (options?.emit === 'always') {
      this.#emit = true;
    }
  }

  get context(): Record<string, string> {
    return this.#context;
  }

  get level(): 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug' {
    return this.#level;
  }

  get shouldEmit(): boolean {
    return this.#emit;
  }
}
