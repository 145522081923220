// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import NoDataCard from '@/components/NoDataCard';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import Link from '@/components/UI/Link';
import SpreadSheet from '@/components/UI/SpreadSheet';
import type { CustomValidation } from '@/components/UI/SpreadSheet/SpreadSheet.model';
import { buildCustomValidators, spreadSheetValidationTooltip } from '@/components/UI/SpreadSheet/SpreadSheet.utils';
import SubHeader from '@/components/UI/SubHeader';
import { successToast } from '@/helpers';
import { _isNil, _isNotEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { MetadataField } from '@/model/Metadata.model';
import { createMeta } from '@/support/hot';
import Http from '@/support/http';
import { api as apiRoutes, web as webRoute } from '@/support/route';
import type HotTable from '@handsontable/react';
import type { CellChange, ChangeSource } from 'handsontable/common';
import type { GridSettings } from 'handsontable/settings';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getMetaColumns, initAnimalSettings, initialBulkAnimalData, updateStrainSelectDropdown } from '../Colony.utils';
import '../Colony.scss';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook';

type MetadataResponse = { data: Array<MetadataField> } | undefined;
const BulkAnimalEdit = () => {
  const history = useHistory();
  const ref = useRef<HotTable>();
  const [data, setData] = useState([]);
  const [settings, setSettings] = useState<GridSettings | null>(null);
  const [apiError, setApiError] = useState<Error | false>(false);
  const [metadata, setMetadata] = useState<Array<MetadataField>>([]);
  const [loading, setLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState<boolean>();

  const { id: studyId } = useParams<{ id: string }>();

  const selectedAnimals: Array<Animal> = history.location.state.animals ?? [];
  const { response: animalMetadataResponse, loading: animalMetadataLoading } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'meta-glossary.show',
    query: { filter_type: 'subject_meta' },
    options: { onError: { throw: false, capture: true, toast: false, slug: 'bulk-animal-edit-fetch-metadata' } },
  });
  const { response: speciesResponse, loading: speciesLoading } = useApiHook({
    endpoint: 'GET /api/v1/species',
    invokeOnInit: true,
    query: { include: ['strains'] },
    options: { onError: { throw: false, capture: true, toast: false, slug: 'bulk-animal-edit-fetch-species' } },
  });
  const metaColumns = useMemo(() => {
    const metadataResponseData: Array<MetadataField> = (animalMetadataResponse?.body as MetadataResponse)?.data;
    if (_notNil(metadataResponseData) && _isNotEmpty(selectedAnimals)) {
      return getMetaColumns(selectedAnimals, metadataResponseData);
    }
    return null;
  }, [animalMetadataResponse, selectedAnimals]);

  const initialLoad =
    _isNil(animalMetadataResponse?.body) || _isNil(speciesResponse?.body) || animalMetadataLoading || speciesLoading;
  const species = useMemo(() => speciesResponse?.body?.data ?? [], [speciesResponse]);
  const availableMetadata = useMemo(
    () => (animalMetadataResponse?.body as MetadataResponse)?.data ?? [],
    [animalMetadataResponse]
  );

  const customValidators = useMemo<Record<string, CustomValidation>>(() => {
    return buildCustomValidators(settings?.columns ?? []);
  }, [settings?.columns]);

  useEffect(() => {
    if (!initialLoad && _notNil(metaColumns) && _isNil(settings) && _isNotEmpty(selectedAnimals)) {
      const removeRow = false;
      const newSettings = initAnimalSettings(species, true, removeRow, metaColumns, availableMetadata);
      if (newSettings.settings) {
        setSettings(newSettings.settings);
      }
      setMetadata(_isNil(newSettings.metadata) ? availableMetadata : newSettings.metadata);
      setData(initialBulkAnimalData(selectedAnimals, species));
    }
  }, [initialLoad, selectedAnimals, species, availableMetadata, metaColumns, settings]);

  const patchMetadata = () => {
    if (ref.current) {
      const hot = ref.current.hotInstance;
      if (_notNil(hot)) {
        const col = hot.propToCol('species');
        const colData = hot.getDataAtCol(col);

        colData.forEach((value, row) => {
          updateStrainSelectDropdown(hot, species, row, value);
        });
        hot.render();
      }
    }
  };

  const backRoute = useMemo(
    () => webRoute(_notNil(studyId) ? 'studies.animals' : 'colony', { id: studyId }),
    [studyId]
  );

  const afterChange = (changes: CellChange[] | null, source: ChangeSource | 'insert_row'): void => {
    const hot = ref?.current?.hotInstance;

    if (changes) {
      patchMetadata?.();
    }

    changes?.forEach((change) => {
      hot?.validateColumns(
        customValidators?.[hot.colToProp(change[1])]?.linkedColumns?.map((linkedColumn) =>
          hot.propToCol(linkedColumn)
        ) ?? []
      );
    });
  };

  const mapAnimalPatchData = () =>
    data.map(({ id, name, sex, dob, species: animalSpecies, strain, meta }) => {
      const animal = selectedAnimals.find((a) => a.id === id);
      const returnData = {
        id,
        cage_id: animal?.cage_id ?? null,
        name,
        dob,
      };
      if (sex) {
        returnData.sex = sex === 'Male' ? 'm' : 'f';
      }
      if (animalSpecies) {
        const strains = species.find((s) => s.name === animalSpecies).strains;
        if (strains.length) {
          returnData.strain_id = strains.find((s) => s.name === strain).id;
        }
      }
      if (_isNotEmpty(meta)) {
        returnData.meta = createMeta(meta, settings.columns);
      }
      return returnData;
    });

  const saveAnimals = async (reasonForChange?: string) => {
    setLoading(true);
    const animals = mapAnimalPatchData();
    try {
      await Http.patch(apiRoutes('animals.updateMany'), {
        animals,
        ...(_notNil(reasonForChange) ? { reason_for_change: reasonForChange } : {}),
      });
      history.push(backRoute);
      successToast(`Successfully added ${animals.length > 1 ? 'animals' : 'animal'}`);
    } catch (error) {
      setApiError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  const saveAction = async () => await saveAnimals();

  const spreadSheet = (
    <>
      <div className="ow-spreadsheet-styles">
        <SpreadSheet
          data={data}
          settings={settings}
          setSettings={setSettings}
          height="500"
          className="pl4 pr4"
          innerRef={ref}
          metadata={metadata}
          setMetadata={setMetadata}
          afterChange={afterChange}
          setInvalid={setIsInvalid}
          patchMetadataAfterSettingsUpdated={patchMetadata}
          maxRows={data.length}
          testPrefix="bulk-animals"
        />
      </div>
      <div className="pa4">
        <Button
          onClick={saveAction}
          disabled={loading || isInvalid}
          tooltip={isInvalid ? spreadSheetValidationTooltip : undefined}
          testId="bulk-animals__submit"
        >
          Save
        </Button>
        <Button plain className="ml3" onClick={() => history.push(backRoute)}>
          Cancel
        </Button>
      </div>
    </>
  );

  return (
    <div className="h-100">
      {apiError && (
        <ApiErrorBanner
          className="mb4"
          title={'There was an error with your request'}
          text={
            'An error has occurred when submitting your request, please try again later. If this keeps occurring please contact support.'
          }
          error={apiError}
        />
      )}
      {loading || initialLoad || _isNil(settings) ? (
        <div style={{ height: 650 }}>
          <Loading txt={`${loading ? 'Updating animal...' : 'Loading...'}`} />
        </div>
      ) : (
        <>
          <SubHeader
            linkToText={_notNil(studyId) ? 'Animals' : 'Colony'}
            link={backRoute}
            testId="bulk-animals__return"
          />
          <div className="ph4">
            <Header mainHeaderText="Edit Animals" />
          </div>
          {_notNil(settings) && _isNotEmpty(data) ? (
            spreadSheet
          ) : (
            <div className="ma4">
              <NoDataCard
                title="You have not selected any animals"
                text={'Please select animals from inside Colony to proceed'}
                NoDataComponent={
                  <Link to={backRoute} className="link blue">
                    {`Go to ${_notNil(studyId) ? 'Animals' : 'Colony'}`}
                  </Link>
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BulkAnimalEdit;
