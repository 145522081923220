import { FC, useMemo } from 'react';
import type { TaskDuration } from '@/model/Task.model';
import { useSelector } from 'react-redux';
import { State } from '@/model/State.model';
import { DateTimeRenderFormat, DateUtils } from '@/utils/Date.utils';
import { TZDate } from '@date-fns/tz';
import { differenceInMinutes, format } from 'date-fns';
import { _isEmptyString } from '@/littledash';
import { RiTimeLine } from 'react-icons/ri';

export const TaskTimeChip: FC<{ taskDuration: TaskDuration; timezone: string }> = ({ taskDuration, timezone }) => {
  const userTimezone = useSelector((state: State) => state.user.currentUser.timezone);
  const { timeString, timezoneString } = useMemo(() => {
    const displayTimezone =
      DateUtils.timezoneOffsetMilliseconds(taskDuration.start, timezone) !==
      DateUtils.timezoneOffsetMilliseconds(taskDuration.start, userTimezone);
    const startDate = DateUtils.roundDate(new TZDate(taskDuration.start, userTimezone), 'minutes');
    const endDate = DateUtils.roundDate(new TZDate(taskDuration.end, userTimezone), 'minutes');
    const startTimeString = format(startDate, DateTimeRenderFormat.Time);
    const endTimeString = format(endDate, DateTimeRenderFormat.Time);
    const durationInMinutes = differenceInMinutes(endDate, startDate);
    const durationInHours = durationInMinutes / 60;
    const allDayTask = durationInHours >= 24;
    const durationString = `${durationInHours % 1 === 0 ? Math.floor(durationInHours) : durationInHours.toFixed(1)}hr${durationInHours > 1 ? 's' : ''}`;
    const timezoneData = {
      id: timezone,
      name: DateUtils.timezoneName(taskDuration.start, timezone, 'long') ?? '',
      abbreviation: DateUtils.timezoneAbbreviation(taskDuration.start, timezone),
    };
    const timezoneString = displayTimezone
      ? `${_isEmptyString(timezoneData.name) ? timezoneData.id : timezoneData.name} • ${timezoneData.abbreviation}`
      : undefined;

    if (allDayTask) {
      if (displayTimezone || durationInHours > 24) {
        return { timeString: `${startTimeString} for ${durationString}`, timezoneString };
      } else {
        return { timeString: '', timezoneString };
      }
    } else {
      if (displayTimezone) {
        return {
          timeString: `${startTimeString} for ${durationString}`,
          timezoneString,
        };
      } else {
        return {
          timeString: `${startTimeString} to ${endTimeString}`,
          timezoneString,
        };
      }
    }
  }, [userTimezone, taskDuration, timezone]);
  if (!_isEmptyString(timeString)) {
    return (
      <span
        className="bg-near-white b--moon-gray pa1 f7 ba br2 near-black"
        data-tooltip-id="global-tooltip-id"
        data-tooltip-content={timezoneString}
      >
        <RiTimeLine className="v-mid" />
        <span className="ml2 v-mid">{timeString}</span>
      </span>
    );
  }
  return null;
};
