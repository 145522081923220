import { _first } from '@/littledash';
import { DateUtils } from '@/utils/Date.utils';
import { differenceInDays, parse } from 'date-fns';
import type { TaskSpecCreate, TaskType } from 'model/Task.model';
import {
  AddTaskExecutionData,
  AddTaskOverviewData,
  AddTaskScheduleData,
  AddTaskTargetData,
  StepFormPayloadProps,
  WorkflowTemplateDosingV1,
  WorkflowTemplateMeasurementV1,
  WorkflowTemplateObservationV1,
  WorkflowTemplateSampleV1,
} from './AddTask.model';
import { conditionTypeOptions } from './Steps/AddTaskScheduleForm/AddTaskScheduleConditionalFields';
import { repeatPeriodOptions } from './Steps/AddTaskScheduleForm/AddTaskScheduleForm.utils';

/** Based on the `type` of schedule form data it structures the corresponding API payload */
export const handleAddTaskSchedulePayload = (
  addTaskScheduleFormData: AddTaskScheduleData
): TaskSpecCreate['schedule'] => {
  switch (addTaskScheduleFormData.type) {
    case 'recurring':
      return {
        type: addTaskScheduleFormData.type,
        repeat: {
          value: addTaskScheduleFormData.repeat?.value ?? NaN,
          unit: addTaskScheduleFormData.repeat?.unit ?? repeatPeriodOptions.days.value,
          on_day: addTaskScheduleFormData?.repeat?.days ?? [],
        },
        duration: {
          type: 'date',
          start: addTaskScheduleFormData?.start ?? DateUtils.dateNow(),
          end: addTaskScheduleFormData?.end ?? DateUtils.dateNow(),
        },
        time: addTaskScheduleFormData?.time ?? { type: 'all_day' },
      };
    case 'conditional':
      return {
        type: addTaskScheduleFormData.type,
        condition: {
          type: addTaskScheduleFormData.condition?.type ?? _first(conditionTypeOptions).value,
          value: addTaskScheduleFormData.condition?.value ?? [],
          unit: 'days', // currently static
        },
        time: addTaskScheduleFormData?.time ?? { type: 'all_day' },
      };
    case 'one_off':
      return {
        type: addTaskScheduleFormData.type,
        duration: {
          type: 'date',
          start: addTaskScheduleFormData?.start ?? DateUtils.dateNow(),
          end: addTaskScheduleFormData?.start ?? DateUtils.dateNow(),
        },
        time: addTaskScheduleFormData?.time ?? { type: 'all_day' },
      };
  }
};

export const handleAddTaskTargetPayload = (addTaskTargetFormData: AddTaskTargetData): TaskSpecCreate['target'] => {
  switch (addTaskTargetFormData.type) {
    case 'animal':
      return {
        type: 'animal',
      };
    case 'group':
      return {
        type: 'group',
        groups: addTaskTargetFormData?.groups ?? [],
      };
  }
};
export const handleAddTaskExecutionPayload = (
  taskType: AddTaskOverviewData['type'],
  addTaskExecutionData: AddTaskExecutionData
): TaskSpecCreate['execution'] => {
  switch (taskType) {
    case 'measurement': {
      const { measurements, assign_identifier, weight_percentage_change, cursor_position } =
        addTaskExecutionData as WorkflowTemplateMeasurementV1;
      return {
        type: 'workflow',
        measurement: {
          measurements,
          assign_identifier,
          weight_percentage_change,
          cursor_position,
          after_save_action: 'focus_search_bar',
        },
        sample: null,
        observation: null,
        dosing: null,
      };
    }
    case 'sample': {
      const { samples, auto_generate_sample_id } = addTaskExecutionData as WorkflowTemplateSampleV1;
      return {
        type: 'workflow',
        measurement: null,
        sample: { samples, auto_generate_sample_id, after_save_action: 'focus_search_bar' },
        observation: null,
        dosing: null,
      };
    }

    case 'observation': {
      const { observations } = addTaskExecutionData as WorkflowTemplateObservationV1;
      return {
        type: 'workflow',
        measurement: null,
        sample: null,
        observation: { observations, after_save_action: 'focus_search_bar' },
        dosing: null,
      };
    }
    case 'dosing': {
      const { treatments, skip_dosing_for_unrequired_animals, use_new_body_weight } =
        addTaskExecutionData as WorkflowTemplateDosingV1;
      return {
        type: 'workflow',
        measurement: null,
        sample: null,
        observation: null,
        dosing: {
          treatments,
          skip_dosing_for_unrequired_animals,
          use_new_body_weight,
          after_save_action: 'focus_search_bar',
        },
      };
    }
    default:
      return null;
  }
};

/** Turn each step form section data into API Payload */
export const handleAddTaskStepFormPayload = (stepFormData: StepFormPayloadProps): TaskSpecCreate => {
  const { title, description, type } = stepFormData.overview;
  const { assignees } = stepFormData;
  return {
    title,
    description,
    type: type as TaskType,
    assignees,
    execution: handleAddTaskExecutionPayload(type, stepFormData.execution),
    schedule: handleAddTaskSchedulePayload(stepFormData.schedule),
    target: handleAddTaskTargetPayload(stepFormData.target),
  };
};

export const calculateDaysDifference = (startDateString: string, endDateString: string): number => {
  const startDate = parse(startDateString, 'yyyy-MM-dd', new Date());
  const endDate = parse(endDateString, 'yyyy-MM-dd', new Date());
  return differenceInDays(endDate, startDate);
};
export const taskTypeOptions: Array<{ value: TaskType; label: string }> = [
  { value: 'measurement', label: 'Measurement' },
  { value: 'sample', label: 'Sample' },
  { value: 'observation', label: 'Observation' },
  { value: 'dosing', label: 'Dosing' },
  { value: 'other', label: 'Other' },
] as const;
