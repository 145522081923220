import Button from '@/components/UI/Button';
import DatePickerNative from '@/components/UI/DatePickerNative';
import Radio from '@/components/UI/FormElements/Radio';
import { DateUtils } from '@/utils/Date.utils';
import type { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form@latest';
import { AddTaskScheduleData, StepFormPayloadProps, type StepRenderProps } from '../../AddTask.model';
import styles from '../../AddTask.module.scss';
import ConditionalFields from './AddTaskScheduleConditionalFields';
import RecurringFields from './AddTaskScheduleRecurringFields';
import { oneOffDateValidator } from './AddTaskScheduleForm.utils';
import { DurationPicker } from '@/components/UI/DurationPicker/DurationPicker';
import { createSelector } from '@reduxjs/toolkit';
import { featuresSelector } from '@/support/Selectors';
import { useSelector } from 'react-redux';

interface AddTaskScheduleFormProps extends Omit<StepRenderProps, 'defaultValues'> {
  defaultValues?: AddTaskScheduleData;
}

const selector = createSelector([featuresSelector], (features) => ({
  taskDurationEnabled: features?.task_duration ?? false,
}));

export const AddTaskScheduleForm: FC<AddTaskScheduleFormProps> = ({
  onSubmit,
  onCancel,
  submitButtonText,
  cancelButtonText,
  disabledFields,
}) => {
  const { control, handleSubmit: useFormHandleSubmit, formState, clearErrors } = useFormContext<StepFormPayloadProps>();
  const selectedType = useWatch({ control, name: 'schedule.type' });
  const { taskDurationEnabled } = useSelector(selector);

  const handleScheduleTypeChange = (type: string) => {
    clearErrors();
    return type;
  };

  return (
    <form
      onSubmit={useFormHandleSubmit(onSubmit)}
      className={styles['step-content']}
      data-test-component="AddTaskScheduleForm"
      data-test-element="form"
    >
      <div>
        <div className="ph3 pb3" data-test-component="OneOffScheduleForm" data-test-element="container">
          <Controller
            name="schedule.type"
            control={control}
            defaultValue="one_off"
            render={({ field }) => (
              <Radio
                onChange={(e) => field.onChange(handleScheduleTypeChange(e.target.value))}
                id="one_off"
                value="one_off"
                label="One off"
                checked={selectedType === 'one_off'}
                disabled={disabledFields?.has(`schedule.type`) ?? false}
              />
            )}
          />
          {selectedType === 'one_off' && (
            <div className="pt3 ml4">
              <Controller
                name="schedule.start"
                control={control}
                defaultValue={DateUtils.dateNow()}
                rules={{ validate: (value?: string) => oneOffDateValidator(value) }}
                render={({ field, fieldState }) => (
                  <>
                    <DatePickerNative
                      value={field.value ?? ''}
                      onChange={(c) => field.onChange(c)}
                      className={styles['add-task-input-field']}
                    />
                    {fieldState.error && <span className="f6 red db pt2">{fieldState.error.message}</span>}
                  </>
                )}
              />
              {taskDurationEnabled ? (
                <div className="pt3">
                  <label className="w4 mb2 lh-title">Duration</label>
                  <Controller
                    name="schedule.time"
                    control={control}
                    defaultValue={{ type: 'all_day' }}
                    render={({ field }) => (
                      <DurationPicker
                        value={field.value ?? { type: 'all_day' }}
                        disabled={field.disabled}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>

        <div className="ph3 pb3" data-test-component="RecurringScheduleForm" data-test-element="container">
          <Controller
            name="schedule.type"
            control={control}
            defaultValue="one_off"
            render={({ field }) => (
              <Radio
                onChange={(e) => field.onChange(handleScheduleTypeChange(e.target.value))}
                id="recurring"
                value="recurring"
                label="Recurring"
                checked={selectedType === 'recurring'}
                sublabel="Performed in a recurring pattern (eg. Monday and Wednesday)."
                disabled={disabledFields?.has('schedule.type') ?? false}
              />
            )}
          />
          {selectedType === 'recurring' ? (
            <RecurringFields disabledFields={disabledFields} taskDurationEnabled={taskDurationEnabled} />
          ) : null}
        </div>
        <div className="ph3 pb3" data-test-component="ConditionalScheduleForm" data-test-element="container">
          <Controller
            name="schedule.type"
            control={control}
            defaultValue="one_off"
            render={({ field }) => (
              <Radio
                onChange={(e) => field.onChange(handleScheduleTypeChange(e.target.value))}
                id="conditional"
                value="conditional"
                label="Conditional"
                checked={selectedType === 'conditional'}
                sublabel="Generated once a condition is met (eg. age is 35 days)."
                disabled={disabledFields?.has('schedule.type') ?? false}
              />
            )}
          />
          {selectedType === 'conditional' ? (
            <ConditionalFields disabledFields={disabledFields} taskDurationEnabled={taskDurationEnabled} />
          ) : null}
        </div>
      </div>
      <div className="pa3 bt b--moon-gray mt3">
        <Button submit testKey="taskSpecStepFormSubmit" disabled={!formState.isValid && selectedType === 'one_off'}>
          {submitButtonText}
        </Button>
        <Button plain className="ml2" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </form>
  );
};
