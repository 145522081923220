import { format, intlFormat } from 'date-fns';
import Fuse from 'fuse.js';
import { TaskTimeDuration } from '@/model/Task.model';
import { DateUtils } from '@/utils/Date.utils';

type FromOption = { label: string; offset: number; search: string };
type ToOption = FromOption & { duration: number; durationInHours: string };
export type DurationPickerState = {
  allDay: boolean;
  from: number;
  duration: number;
};

export const minimumDuration = 30;
export const minutesInDay = 24 * 60;

export const initialState = (value: TaskTimeDuration): DurationPickerState => {
  if (value.type === 'all_day') {
    return { allDay: true, from: -1, duration: minimumDuration };
  }
  const fromDate = DateUtils.isoTimeToDate(value.from);
  const toDate = DateUtils.isoTimeToDate(value.to);
  const from = fromDate.getHours() * 60 + fromDate.getMinutes();
  const to = toDate.getHours() * 60 + toDate.getMinutes();
  return { allDay: false, from, duration: to - from };
};
const offsetToDate = (offset: number): Date => new Date(0, 0, 0, 0, offset);
const formatFromOptionOffset = (offset: Date): Pick<FromOption, 'label' | 'search'> => {
  return {
    label: intlFormat(offset, { hour: 'numeric', minute: '2-digit' }),
    search: format(offset, 'HH haa bb'),
  };
};
const formatDuration = (duration: number): string => {
  const hours = duration / 60;
  return `${hours % 1 === 0 ? Math.floor(hours) : hours.toFixed(1)}hr`;
};
const formatToOptionOffset = (
  offset: Date,
  duration: number
): Pick<ToOption, 'label' | 'search' | 'durationInHours'> => {
  const { label, search } = formatFromOptionOffset(offset);
  const formattedDuration = formatDuration(duration);
  return {
    label: `${label} (${formattedDuration})`,
    search,
    durationInHours: formattedDuration,
  };
};

export const fromOptionData = () => {
  const fromOptionsMap = Array.from({ length: minutesInDay / minimumDuration }).reduce<Map<number, FromOption>>(
    (acc, _, index) => {
      const offset = index * minimumDuration;
      return acc.set(offset, { offset, ...formatFromOptionOffset(offsetToDate(offset)) });
    },
    new Map<number, FromOption>()
  );
  const fromOptions = [...fromOptionsMap.values()].sort((left, right) => left.offset - right.offset);
  const fromOptionsFuse = new Fuse(fromOptions, {
    includeScore: false,
    threshold: 0.15,
    ignoreLocation: true,
    keys: [{ name: ['label'] }, { name: ['search'] }],
  });

  return {
    fromOptionsMap,
    fromOptions,
    fromOptionsFuse,
  };
};
export const allDayToOption: ToOption = {
  duration: minutesInDay,
  offset: minutesInDay,
  ...formatToOptionOffset(offsetToDate(minutesInDay), minutesInDay),
};

export const toOptionData = (fromOffset: number) => {
  const toOptionsMap = new Map<number, ToOption>();
  for (let offset = fromOffset + minimumDuration; offset <= minutesInDay; offset += minimumDuration) {
    const duration = offset - fromOffset;
    toOptionsMap.set(duration, {
      duration,
      offset,
      ...formatToOptionOffset(offsetToDate(offset), duration),
    });
  }
  const toOptions = [...toOptionsMap.values()].sort((left, right) => left.duration - right.duration);
  const toOptionsFuse = new Fuse(toOptions, {
    includeScore: false,
    threshold: 0.15,
    ignoreLocation: true,
    keys: [{ name: ['label'] }, { name: ['search'] }, { name: ['durationInHours'] }],
  });
  return {
    toOptionsMap,
    toOptions,
    toOptionsFuse,
  };
};
export const toggleAllDayTask = (prev: DurationPickerState): DurationPickerState => {
  const now = new Date();
  if (prev.from < 0) {
    const from = Math.ceil((now.getHours() * 60 + now.getMinutes()) / 30) * 30;
    return from + prev.duration > minutesInDay
      ? { ...prev, allDay: !prev.allDay, from: minutesInDay - prev.duration }
      : { ...prev, allDay: !prev.allDay, from };
  }
  return { ...prev, allDay: !prev.allDay };
};

export const stateToDurationValue = (state: DurationPickerState): TaskTimeDuration => {
  if (state.allDay) {
    return { type: 'all_day' };
  }
  const from = format(offsetToDate(state.from), 'HH:mm');
  const to = format(offsetToDate(state.from + state.duration), 'HH:mm');
  return { type: 'timed', from, to };
};
