import Loading from '@/components/Loading';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import { Dropdown, DropdownMenuDivider, DropdownMenuItem } from '@/components/UI/Dropdown';
import APITable from '@/components/UI/Table/Reusable/APITable';
import type { Columns } from '@/components/UI/Table/TableComponent.model';
import UserAvatar from '@/components/UI/UserAvatar';
import { capitalise, errorToast, successToast } from '@/helpers';
import { _isNil } from '@/littledash';
import { DataTableTemplateApiId, DataTableTemplateInfoV1 } from '@/model/DataTable.model';
import InVivoError from '@/model/InVivoError.ts';
import { State } from '@/model/State.model.ts';
import type { Study } from '@/model/Study.model';
import * as Auth from '@/support/auth';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook.ts';
import { RouteService } from '@/support/RouteService';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import { useModalAction } from '@/utils/modal';
import { FC, useMemo } from 'react';
import { RiMoreFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const DataTableTemplatesList = () => {
  const { openModal, closeModal } = useModalAction();
  const { id: studyId } = useParams<{ id: string }>();

  const { response: studyResponse, loading: studyLoading } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'studies.show.p',
    path: { id: studyId },
    query: { include: 'users' },
    options: { onError: { toast: true, slug: 'study-load' } },
  });

  const study = (studyResponse?.body as { data: Study })?.data;

  const isStudyWriteUser = Auth.isWriteUserForStudy(study as Study);
  const isTenantAdminUser = Auth.isCurrentTeamOwner();
  const settings = useSelector((state: State) => state.ui.settings);

  const { invoke: invokeDeleteDataTableTemplate } = useApiHook({
    endpoint: 'DELETE /api/v1/datatables/templates/{datatableTemplateApiId}',
    path: { datatableTemplateApiId: 'dtt_empty' },
    invokeOnInit: false,
  });
  const { invoke: invokePublishDataTableTemplate } = useApiHook({
    endpoint: 'POST /api/v1/datatables/templates/{datatableTemplateApiId}/publish',
    invokeOnInit: false,
    options: { onError: { toast: false, slug: 'data-table-template-publish', capture: false } },
  });
  const handleDataTableTemplatePublish = async (datatableTemplateApiId: DataTableTemplateApiId): Promise<boolean> =>
    new Promise((resolve) => {
      openModal('DATATABLE_TEMPLATE_PUBLISH', {
        closeModal: () => {
          closeModal();
          resolve(false);
        },
        publish: async ({ locked }: { locked: boolean }) => {
          try {
            await invokePublishDataTableTemplate({ path: { datatableTemplateApiId } });
            successToast('Template published');
            closeModal();
            resolve(true);
          } catch (err) {
            errorToast('Could not publish datatable template');
            const ivError = new InVivoError('Could not publish datatable template', {
              slug: 'publish-data-table-template',
              cause: err,
              context: { datatableTemplateApiId },
            });
            ExceptionHandler.captureException(ivError);
            resolve(false);
          }
        },
      });
    });
  const handleDataTableTemplateDelete = async (datatableTemplateApiId: DataTableTemplateApiId): Promise<boolean> =>
    new Promise((resolve) => {
      openModal('CONFIRM_DELETE_DATATABLE_TEMPLATE', {
        onClick: async () => {
          try {
            await invokeDeleteDataTableTemplate({ path: { datatableTemplateApiId } });
            successToast('Deleted template');
            resolve(true);
          } catch (err) {
            ExceptionHandler.captureException(
              new InVivoError('Could not delete data table template', {
                slug: 'delete-data-table-template',
                cause: err,
                context: { datatableTemplateApiId },
              })
            );
            errorToast('Could not delete data table template');
            resolve(false);
          }
        },
      });
    });

  const handleDataTableTemplateEdit = (
    dataTableTemplateName: string,
    dataTableTemplateApiId: DataTableTemplateApiId
  ): Promise<boolean> =>
    new Promise<boolean>((resolve) => {
      openModal('RENAME_DATA_TABLE_TEMPLATE', {
        dataTableTemplateName,
        dataTableTemplateApiId,
        closeModal: () => {
          closeModal();
          resolve(false);
        },
        onTemplateRename: () => resolve(true),
      });
    });

  const columns: Columns<DataTableTemplateInfoV1> = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        sortBy: 'name',
        isVisible: true,
      },
      {
        id: 'created_at',
        Header: 'Created on',
        accessor: 'created_at',
        sortBy: 'created_at',
        isVisible: true,
        Cell: ({ row: { original } }) => (original ? <DateTimeRenderer value={original.created_at} /> : null),
      },
      {
        id: 'type',
        Header: 'Type',
        accessor: 'type',
        sortBy: 'type',
        isVisible: true,
        sortDisabled: true,
      },
      {
        id: 'created_by',
        Header: 'Created by',
        isVisible: true,
        sortBy: 'created_by',
        accessor: 'created_by',
        Cell: ({ row: { original } }) =>
          original && original.created_by ? (
            <UserAvatar user={original.created_by} className="ml1 dib" tooltip={original.created_by.name} />
          ) : null,
      },
      {
        id: 'updated_at',
        Header: 'Updated on',
        accessor: 'updated_at',
        sortBy: 'updated_at',
        isVisible: true,
        Cell: ({ row: { original } }) => (original ? <DateTimeRenderer value={original.updated_at} /> : null),
      },
      {
        id: 'visibility',
        Header: 'Visibility',
        accessor: 'visibility',
        sortBy: 'visibility',
        sortDisabled: true,
        isVisible: true,
        Cell: ({ row: { original } }) => (original ? capitalise(original.visibility) : null),
      },
      {
        id: 'actions',
        sortDisabled: true,
        isVisible: isStudyWriteUser,
        width: 35,
        Cell: ({ apiTable: { fetchTableData }, row: { original } }) => (
          <TemplateListActions
            published={original.visibility === 'published'}
            handleDataTableTemplateEdit={() =>
              handleDataTableTemplateEdit(original.name, original.api_id).then((reload) =>
                reload ? fetchTableData() : Promise.resolve()
              )
            }
            handleDataTableTemplatePublish={() =>
              handleDataTableTemplatePublish(original.api_id).then((reload) =>
                reload ? fetchTableData() : Promise.resolve()
              )
            }
            handleDataTableTemplateDelete={() =>
              handleDataTableTemplateDelete(original.api_id).then((reload) =>
                reload ? fetchTableData() : Promise.resolve()
              )
            }
          />
        ),
      },
    ],
    [isTenantAdminUser, isStudyWriteUser, study]
  );

  if (studyLoading || _isNil(study)) {
    return (
      <div style={{ height: 600 }}>
        <Loading txt="Loading templates" />
      </div>
    );
  }

  return (
    <div className="h-100">
      <div className="">
        <APITable
          apiInfo={{
            type: 'internalApi',
            route: RouteService.api({
              endpoint: 'GET /api/v1/datatables/templates',
              path: undefined,
            }).url,
          }}
          columns={columns}
          defaultSortBy={{ id: 'created_at', desc: false }}
          searchPlaceholderText="Search"
          settings={settings}
          reduxTableName={'dataTableTemplates'}
          testIdPrefix={'dataTableTemplates'}
        />
      </div>
    </div>
  );
};

interface TemplateListActionProps {
  published: boolean;
  handleDataTableTemplateEdit: () => Promise<void>;
  handleDataTableTemplateDelete: () => Promise<void>;
  handleDataTableTemplatePublish: () => Promise<void>;
}

const TemplateListActions: FC<TemplateListActionProps> = ({
  published,
  handleDataTableTemplateEdit,
  handleDataTableTemplateDelete,
  handleDataTableTemplatePublish,
}) => {
  const isTenantAdminUser = Auth.isCurrentTeamOwner();
  if (!isTenantAdminUser && published) {
    return null;
  }

  return (
    <Dropdown
      renderMenu={() => (
        <>
          {!published ? <DropdownMenuItem onClick={handleDataTableTemplateEdit}>Edit</DropdownMenuItem> : null}
          {isTenantAdminUser && !published ? (
            <DropdownMenuItem onClick={handleDataTableTemplatePublish}>
              <span>Publish</span>
            </DropdownMenuItem>
          ) : null}
          <DropdownMenuDivider />
          <DropdownMenuItem onClick={handleDataTableTemplateDelete}>
            <span className="red">Delete</span>
          </DropdownMenuItem>
        </>
      )}
    >
      <RiMoreFill size={22} />
    </Dropdown>
  );
};
