import {
  CreateOrUpdatePresetContext,
  DraftMeasurement,
  toSlug,
  toTitle,
} from '@/components/Glossary/Sections/Presets/Builder/PresetBuilder.util';
import Icon from '@/components/UI/Icon';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import { _notNil } from '@/littledash.ts';
import { PresetCreateOrUpdateV1 } from '@/model/Preset.model.ts';
import { useModalAction } from '@/utils/modal.tsx';
import cn from 'classnames';
import { FC, useContext, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form@latest';
import { RiAlertFill, RiCheckFill, RiCloseFill, RiPencilLine } from 'react-icons/ri';
import style from './PresetBuilder.module.scss';

export const MeasurementsForm: FC<{
  handleMeasurementSelected: (selectedIndex: number) => void;
}> = ({ handleMeasurementSelected }) => {
  const { openModal, closeModal } = useModalAction();
  const { state, dispatch, updatePresetState } = useContext(CreateOrUpdatePresetContext);
  const moveMeasurement = (from: number, to: number) => {
    if (_notNil(state.preset?.measurements?.[from]) && _notNil(state.preset?.measurements?.[to])) {
      const measurements = [...(state.preset as PresetCreateOrUpdateV1).measurements];
      measurements.splice(to, 0, measurements.splice(from, 1)[0]);
      updatePresetState({ ...(state.preset as PresetCreateOrUpdateV1), measurements });
    }
  };
  const removeMeasurement = (index: number) => {
    if (_notNil(state.preset?.measurements?.[index])) {
      const measurements = [...(state.preset as PresetCreateOrUpdateV1).measurements];
      measurements.splice(index, 1);
      updatePresetState({ ...(state.preset as PresetCreateOrUpdateV1), measurements });
    }
  };
  const updateMeasurementName = (index: number, name: string) => {
    const measurement = state.preset?.measurements?.[index];
    if (_notNil(measurement)) {
      const preset = { ...(state.preset as PresetCreateOrUpdateV1) };
      preset.measurements[index] = { ...measurement, name, inputs: [{ ...measurement.inputs[0], name }] };
      updatePresetState(preset);
    }
  };

  const addCalculatedMeasurement = () => {
    const preset = state.preset as PresetCreateOrUpdateV1;
    updatePresetState({
      ...preset,
      measurements: [
        ...preset.measurements,
        {
          name: '',
          unit: '',
          slug: '',
          formula: '',
          inputs: [],
          config: {
            all_inputs_required: true,
            auto_swap: null,
            data_analysis: {
              survival: true,
              tolerance: true,
              tolerance_survival: true,
              efficacy: false,
              efficacy_prophylactic: false,
              oncology: false,
            },
          },
        },
      ],
    }).finally(() => handleMeasurementSelected(preset.measurements.length));
  };

  const handleDraftMeasurementCommit = ({ id, name, unit }: DraftMeasurement) => {
    dispatch({ type: 'remove-draft-measurement', data: { id } });
    const slug = toSlug(name);
    updatePresetState({
      ...(state.preset as PresetCreateOrUpdateV1),
      measurements: [
        ...(state.preset?.measurements ?? []),
        {
          name,
          slug,
          unit,
          formula: `$${slug}`,
          inputs: [{ name, slug, unit }],
          config: {
            all_inputs_required: true,
            auto_swap: null,
            data_analysis: {
              survival: true,
              tolerance: true,
              tolerance_survival: true,
              efficacy: false,
              efficacy_prophylactic: false,
              oncology: false,
            },
          },
        },
      ],
    });
  };
  return (
    <div className={`ui-card pa4 mr2 ${style.measurementsForm}`}>
      <h3 className="black fw5 pb2">Measurements</h3>
      <p className="flex flex-wrap items-center dark-gray pb3">
        Reorder by dragging the <Icon icon="drag" className="mh1 mid-gray" width={20} height={20} />
        icon or click the name to edit.
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="dib ml1 link blue"
          href="https://help.benchling.com/hc/en-us/articles/29260886232589-Creating-and-managing-presets"
        >
          Read more
        </a>
      </p>
      {state.preset?.measurements?.map((measurement, measurementIndex) => (
        <MeasurementCard
          key={`${measurement.slug}-${measurementIndex}`}
          index={measurementIndex}
          name={measurement.name}
          unit={measurement.unit ?? ''}
          slug={measurement.slug}
          invalid={_notNil(state.formState.errors?.measurements?.[measurementIndex])}
          disabled={measurement.slug === 'weight' || state.formState.isSubmitting}
          handleMeasurementSelected={handleMeasurementSelected}
          move={moveMeasurement}
          remove={removeMeasurement}
          onNameEdit={(index: number) => {
            openModal('WEIGHT_TITLE_EDIT', {
              name: measurement.name,
              onUpdate: ({ name }: { name: string }) => {
                closeModal();
                updateMeasurementName(index, name);
              },
              onCancel: closeModal,
            });
          }}
        />
      ))}
      {Array.from(state.draftMeasurements.values()).map((draft) => (
        <DraftMeasurementCard
          key={draft.id}
          draft={draft}
          handleCommit={handleDraftMeasurementCommit}
          handleUpdate={(data) => dispatch({ type: 'update-draft-measurement', data })}
          handleRevert={(id) => dispatch({ type: 'remove-draft-measurement', data: { id } })}
        />
      ))}
      <div className="mt3">
        <AddMeasurementButton
          addMeasurement={() => dispatch({ type: 'add-draft-measurement' })}
          addCalculatedMeasurement={addCalculatedMeasurement}
          disabled={state.formState.isSubmitting}
        />
      </div>
    </div>
  );
};

const AddMeasurementButton: FC<{
  addMeasurement: () => void;
  addCalculatedMeasurement: () => void;
  disabled: boolean;
}> = ({ addMeasurement, addCalculatedMeasurement, disabled }) => {
  const actions = [
    {
      name: 'Without calculation',
      action: addMeasurement,
    },
    {
      name: 'With calculation',
      action: addCalculatedMeasurement,
    },
  ];
  return (
    <SelectDropDown
      title="Add measurement"
      className="plain"
      disabled={disabled}
      testId="add-measurement-for-preset-options"
    >
      <ActionList actions={actions} testPrefix="addMeasurement__" />
    </SelectDropDown>
  );
};

const DraftMeasurementCard: FC<{
  draft: DraftMeasurement;
  handleCommit: (draft: DraftMeasurement) => void;
  handleUpdate: (draft: DraftMeasurement) => void;
  handleRevert: (id: string) => void;
}> = ({ draft, handleCommit, handleUpdate, handleRevert }) => {
  const formMethods = useForm<Pick<DraftMeasurement, 'name' | 'unit'>>({
    mode: 'onBlur',
    defaultValues: { name: draft.name, unit: draft.unit },
  });
  const updatedFormData = useWatch({ control: formMethods.control });
  useEffect(() => {
    if (
      formMethods.formState.isValid &&
      formMethods.formState.isDirty &&
      (draft.name !== updatedFormData.name || draft.unit !== updatedFormData.unit)
    ) {
      handleUpdate({ id: draft.id, ...formMethods.getValues() });
    }
  }, [draft, updatedFormData, formMethods.formState.isValid, formMethods.formState.isDirty, handleUpdate]);

  const handleSubmit: SubmitHandler<Pick<DraftMeasurement, 'name' | 'unit'>> = (formData) =>
    handleCommit({ ...draft, ...formData });

  return (
    <div
      className="flex flex-row items-center mt2 justify-start w-100"
      data-test-component="DraftMeasurementCard"
      data-test-element="container"
      data-test-key={draft.id}
    >
      <input
        {...formMethods.register('name', { required: 'Name is required' })}
        type="text"
        maxLength={32}
        placeholder="Measurement name"
        data-test-element="name-input"
        autoComplete="off"
        className={`mr2 w-100 ${style.marginBottomReset}`}
      />
      <input
        {...formMethods.register('unit')}
        type="text"
        placeholder="Unit"
        maxLength={20}
        data-test-element="unit-input"
        autoComplete="off"
        className={`${style.marginBottomReset} ${style.draftCardUnitInput}`}
      />
      <div className={`f3 pl2 flex flex-row items-center justify-start ${style.cardActionContainer}`}>
        <div
          className={cn('ml2 mr3 mid-gray hover-dark-gray', {
            ui__disabled: !(formMethods.formState.isValid || formMethods.formState.isDirty),
            ui__valid: formMethods.formState.isValid || formMethods.formState.isDirty,
          })}
          data-test-element="commit-button"
          onClick={formMethods.handleSubmit(handleSubmit)}
        >
          <RiCheckFill />
        </div>
        <div
          className="mid-gray hover-dark-gray"
          data-test-element="revert-button"
          onClick={() => handleRevert(draft.id)}
        >
          <RiCloseFill />
        </div>
      </div>
    </div>
  );
};
const MeasurementCard: FC<{
  index: number;
  name: string;
  slug: string;
  unit?: string;
  invalid: boolean;
  disabled: boolean;
  handleMeasurementSelected: (index: number) => void;
  move: (from: number, to: number) => void;
  remove: (index: number) => void;
  onNameEdit: (index: number) => void;
}> = ({ index, name, slug, unit, invalid, disabled, handleMeasurementSelected, move, remove, onNameEdit }) => {
  const [{ handlerId, isOver, indicatorBelow }, dropRef] = useDrop({
    accept: 'measurement-card',
    // @ts-expect-error: untyped
    canDrop: (item) => item.sourceIndex !== index,
    drop: (item) => {
      // @ts-expect-error: untyped
      if (Number.isFinite(item.sourceIndex) && item.sourceIndex !== index) {
        // @ts-expect-error: untyped
        move(item.sourceIndex, index);
      }
    },
    collect: (monitor) => {
      const item = monitor.getItem();
      return {
        isOver: monitor.canDrop() && monitor.isOver(),
        indicatorBelow: Number.isFinite(item?.sourceIndex) && item.sourceIndex < index,
        handlerId: monitor.getHandlerId(),
      };
    },
  });
  const [{ isDragging, opacity }, dragRef, dragPreviewRef] = useDrag({
    item: { type: 'measurement-card', sourceIndex: index },
    canDrag: () => true,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
      isDragging: monitor.isDragging(),
    }),
  });
  // drag(drop(ref));
  return (
    <div
      ref={dropRef}
      className={cn('flex flex-row justify-start mt2 w-100')}
      data-test-component="MeasurementCard"
      data-test-element="container"
      data-test-key={slug}
    >
      <div
        ref={dragPreviewRef}
        data-handler-id={handlerId ?? ''}
        className={cn('flex flex-row items-center justify-between bg-near-white pa2 br2 mr3 w-100 ba', {
          disabled,
          [style.dropIndicator]: isOver,
          [style.indicatorBelow]: indicatorBelow,
          [style.invalid]: invalid,
          [style.weightMeasurementCard]: slug === 'weight',
        })}
        style={{ opacity, cursor: isDragging ? 'grabbing' : 'default' }}
        onClick={() => {
          if (!disabled) {
            handleMeasurementSelected(index);
          }
        }}
      >
        <div className="flex flex-row items-center justify-start">
          <span ref={dragRef}>
            <Icon className="mid-gray" icon="drag" width={20} height={20} />
          </span>
          <p className={cn('ph2 f6 ml2 black', { ['link blue']: !disabled })}>{toTitle(name, unit)}</p>
          {slug === 'weight' && (
            <span className={style.weightTitleEdit} onClick={() => onNameEdit(index)}>
              <RiPencilLine />
            </span>
          )}
        </div>
        {invalid && <RiAlertFill className="red" />}
      </div>
      <div className={`f3 pl4 flex flex-row items-center justify-start ${style.cardActionContainer}`}>
        {!disabled && (
          <div
            data-test-element="remove-button"
            className="mid-gray hover-dark-gray"
            onClick={() => {
              if (!disabled) {
                remove(index);
              }
            }}
          >
            <RiCloseFill className="flex" />
          </div>
        )}
      </div>
    </div>
  );
};
